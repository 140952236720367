<template>
  <div class="timePicker">
    <div class="calendar">
      <el-date-picker
        v-model="start_stats_day"
        type="date"
        placeholder="开始日期"
        :format="format"
        :value-format="formatValue"
        :size="size"
        :picker-options="pickerOptions"
        @change="changeData('start')"
        class="calendarDate"
      ></el-date-picker>
      ~
      <el-date-picker
        v-model="end_stats_day"
        type="date"
        placeholder="结束日期"
        :format="format"
        :value-format="formatValue"
        :size="size"
        :picker-options="pickerOptions"
        @change="changeData('end')"
        class="calendarDate"
      ></el-date-picker>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    start_stats_dayOld: {
      type: String,
      default: '',
    },
    end_stats_dayOld: {
      type: String,
      default: '',
    },
    pickerOptions: {
      type: Object,
      default: {},
    },
    size: {
      type: String,
      default: 'small',
    },
    init: {
      type: Boolean,
      default: true,
    }

  },
  data() {
    return {
      radio: '', //时间节点
      start_stats_day: '', //开始日期
      end_stats_day: '', //结束日期
      dateMonthValue: '', //月份和日期选择
      format: 'yyyy/MM/dd',
      formatValue: 'yyyyMMdd',
      yyyy: '',
      MM: '',
      dd: '',
      picker_options: {},
    }
  },
  created() {
    const nowdate = new Date()
    this.yyyy =
      // this.start_stats_year =
      // this.end_stats_year =
      nowdate.getFullYear() + ''
    let MM = nowdate.getMonth() + 1,
      dd = nowdate.getDate()
    this.MM = MM <= 9 ? '0' + MM : MM
    this.dd = dd <= 9 ? '0' + dd : dd
    if(this.init) {
      if(this.start_stats_dayOld) {
        this.start_stats_day = this.start_stats_dayOld
      }else{
        this.start_stats_day = this.yyyy + this.MM + '01 '
      }
      if(this.end_stats_dayOld) {
        this.end_stats_day = this.end_stats_dayOld
      }else{
        this.end_stats_day = this.yyyy + this.MM + this.dd
      }
      this.$emit('setTime',{start_stats_day: this.start_stats_day,end_stats_day:this.end_stats_day})
    }
    
    // this.radio = this.radioParent
  },
  computed: {
    type() {
      const arr = {
        年: 'year',
        月: 'monthrange',
        日: 'daterange',
      }
      return arr[this.radio]
    },
   
    startPlaceholder() {
      const arr = {
        年: '开始年份',
        月: '开始月份',
        日: '开始日期',
      }
      return arr[this.radio]
    },
    endPlaceholder() {
      const arr = {
        年: '结束年份',
        月: '结束月份',
        日: '结束日期',
      }
      return arr[this.radio]
    },
  },
  watch: {
    type(val) {
      const yyyy = this.yyyy,
        MM = this.MM,
        dd = this.dd
      switch (val) {
        case 'year':
          this.start_stats_year = yyyy
          this.end_stats_year = yyyy

          break
        case 'monthrange':
          this.dateMonthValue = [yyyy + MM, yyyy + MM]
          break
        case 'daterange':
          this.dateMonthValue = [yyyy + MM + '01', yyyy + MM + dd]
          break
        default:
          break
      }
    },
    radio(value) {
      let timeData = {
        name: 'radio',
        value,
      }
      this.$emit('setTime', timeData)
    },
    start_stats_year(value) {
      
      let timeData = {
        name: 'start_stats_year',
        value,
      }
      this.$emit('setTime', timeData)
    },
    end_stats_year(value) {
      let timeData = {
        name: 'end_stats_year',
        value,
      }
      this.$emit('setTime', timeData)
    },
    dateMonthValue(value) {
      let timeData = {
        name: 'dateMonthValue',
        value,
      }
      this.$emit('setTime', timeData)
    },
  },
  methods: {
    // 年份联动选择处理,开始年份>结束年份
    changeData(type) {
      // if(
      //   this.start_stats_day != ''&&
      //   this.end_stats_day != ''&&
      //   Number(this.start_stats_day)>Number(this.end_stats_day)){
      //   let temp = this.start_stats_day;
      //   this.start_stats_day = this.end_stats_day
      //   this.end_stats_day = temp
      // }
      if(this.start_stats_day != ''&&this.end_stats_day != ''&&Number(this.start_stats_day)>Number(this.end_stats_day)){
        type==='start'?this.end_stats_day='':this.start_stats_day=''
      }
      this.$emit('setTime',{start_stats_day: this.start_stats_day,end_stats_day:this.end_stats_day})
    },
    // 设置时间
    setTime(start,end){
      this.start_stats_day = start
      this.end_stats_day = end

      // let startList = (start+'').split('-'),
      //     endList = end.split('-')

      // startList[1]=startList[1]<=9?'0'+startList[1]:startList[1]
      // startList[2]=startList[2]<=9?'0'+startList[2]:startList[2]
      // endList[1]=Number(endList[1])<=9?'0'+endList[1]:endList[1]
      // endList[2]=Number(endList[2])<=9?'0'+endList[2]:endList[2]
      // this.start_stats_day = startList.join('')
      // this.end_stats_day = endList.join('')
    },
  },
}
</script>
<style lang="scss" scoped>
.timePicker {
  display: flex;
  .calendar {
    //margin-left: px(10);
    display: flex;
    align-items: center;
  }
  .calendarDate {
    ::v-deep .el-input,
    ::v-deep .el-input__inner {
      width: 100%;
    }
  }
}
</style>