<template>
  <div class="orgManage">
    <div class="body">
      <div class="right-top">
        <ul>
          <li>
            <span>机构名称:</span>
            
            <el-input
              v-model="search_data_org.org_title"
              size="mini"
              placeholder="请输入机构名称"
            ></el-input>
          </li>
          <li>
            <span>状态:</span>
            <el-select v-model="search_data_org.is_active" size="mini" placeholder="请选择状态"  clearable>
              <el-option
                v-for="item in option_zt"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-button type="primary" size="mini" @click="get_system_org">搜索</el-button>
            <el-button
              type="primary"
              size="mini"
              @click="$parent.bodyType = 'USER'"
            >
              返回
            </el-button>
          </li>
        </ul>
      </div>
      <div class="right-body">
        <div class="btn-box">
          <el-button type="primary" size="mini" :disabled="disabled_org_add" @click="open_addOrg_dialog()">
            新增
          </el-button>
          <el-button type="warning" size="mini" :disabled="disabled_org_edit" @click="open_editOrg_dialog()">编辑</el-button>
        </div>
        <div>
          <el-table
            style="margin-bottom: 20px"
            :data="tableData_org"
            stripe
            row-key="pk"
            size="small"
            v-loading="loading_org"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @selection-change="selectionchange_org"
            >
            <el-table-column
              align="center"
              type="selection"
              width="55"
            ></el-table-column>
            <el-table-column
              prop="name"
              align="left"
              label="机构/部门名称"
            >
            </el-table-column>
            <el-table-column
              prop="priority"
              align="center"
              label="显示顺序"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="is_active"
              align="center"
              label="状态"
              :width="flexColumnWidth(90)"
            >
              <template slot-scope="scope">
                <div>
                  <span
                    class="state-box"
                    style="backgroundColor: #1bb394"
                    v-if="scope.row.is_active"
                  >
                    正常
                  </span>
                  <span
                    class="state-box"
                    style="backgroundColor: #ed5564"
                    v-else
                  >
                    停用
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="create_time"
              align="center"
              label="创建时间"
              :width="flexColumnWidth(180)"
            ></el-table-column>

            <el-table-column prop="address" align="center" label="操作">
              <template slot-scope="scope">
                <div class="caozuo">
                  <el-button
                    type="primary"
                    size="mini"
                    @click="open_addOrg_dialog(scope.row)"
                  >
                    新增
                  </el-button>
                  <el-button
                    type="warning"
                    size="mini"
                    @click="open_editOrg_dialog(scope.row)"
                  >
                    编辑
                  </el-button>
                  <el-popconfirm
                    confirm-button-text="好的"
                    cancel-button-text="不用了"
                    icon="el-icon-info"
                    icon-color="red"
                    :title="'确定删除吗？'"
                    @confirm="scope.row.data_flag=='org'?del_system_org([scope.row.pk]):del_system_dept([scope.row.pk])"
                  >
                    <el-button
                      slot="reference"
                      type="danger"
                      style="margin: 0 10px"
                      size="mini"
                    >
                      删除
                    </el-button>
                  </el-popconfirm>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :hide-on-single-page="total <= 0"
            background
            layout="total,prev, pager, next,jumper"
            :total="total"
            :page-size="search_data_org.size"
            :current-page.sync="search_data_org.page"
            @current-change="get_system_org"
          />
        </div>
      </div>
    </div>
    <el-dialog
      :title="dialogType_org == 'ADD' ? '新增机构' : '修改机构'"
      :visible.sync="dialogVisible_org"
      width="35%"
    >
      <div class="dialog-body">
        <ul>
          <li>
            <span>
              <i>*</i>
              机构名称:
            </span>
            <p>
              <el-input
                v-model="orgData_param.org_title"
                size="mini"
                placeholder="请输入机构名称"
              ></el-input>
            </p>
          </li>
          
          <li>
            <span><i>*</i>机构类型:</span>
            <p>
              <el-radio v-model="orgData_param.org_type" :label="1" :disabled="dialogType_org == 'EDIT'">医院</el-radio>
              <el-radio v-model="orgData_param.org_type" :label="2" :disabled="dialogType_org == 'EDIT'">经销商</el-radio>
            </p>
          </li>
          <li>
            <span>
              <i>*</i>
              省份:
            </span>
            <p>
              <el-select v-model="orgData_param.province_id" size="mini" placeholder="请选择省份" @change="set_sf" clearable>
                <el-option
                  v-for="item in option_sf"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </p>
          </li>
          <li>
            <span>
              <i>*</i>
              地区:
            </span>
            <p>
              <el-select v-model="orgData_param.city_id" :disabled="!orgData_param.province_id" size="mini" placeholder="请选择地区"  clearable>
                <el-option
                  v-for="item in option_dq"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </p>
          </li>
          <li v-show="orgData_param.org_type==1">
            <span>
              <i>*</i>
              医院等级:
            </span>
            <p>
              <el-select v-model="orgData_param.level_id" size="mini" placeholder="请选择医院等级"  clearable>
                <el-option
                  v-for="item in option_yydj"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </p>
          </li>
          <li>
            <span>
              <i>*</i>
              地址:
            </span>
            <p>
              <el-input
                v-model="orgData_param.org_address"
                size="mini"
                placeholder="请输入地址"
              ></el-input>
            </p>
          </li>
          <li>
            <span>机构负责人:</span>
            <p>
              <el-input
                v-model="orgData_param.org_master"
                size="mini"
                placeholder="请输入机构负责人"
              ></el-input>
            </p>
          </li>
          <li>
            <span>机构邮箱:</span>
            <p>
              <el-input
                v-model="orgData_param.org_email"
                size="mini"
                placeholder="请输入机构邮箱"
              ></el-input>
            </p>
          </li>
          <li>
            <span>机构联系手机号:</span>
            <p>
              <el-input
                v-model="orgData_param.org_phone"
                size="mini"
                placeholder="请输入机构联系手机号"
              ></el-input>
            </p>
          </li>
          <li>
            <span>显示顺序:</span>
            <p>
              <el-input
                v-model="orgData_param.priority"
                size="mini"
                placeholder="请输入显示顺序"
              ></el-input>
            </p>
          </li>
          <li>
            <span><i>*</i>机构状态:</span>
            <p>
              <el-radio v-model="orgData_param.is_active" :label="true">正常</el-radio>
              <el-radio v-model="orgData_param.is_active" :label="false">停用</el-radio>
            </p>
          </li>
        </ul>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="dialogType_org == 'ADD' ? add_system_org() : put_system_org()"
          size="small"
        >
          确 定
        </el-button>
        <el-button @click="dialogVisible_org = false" size="small">
          取 消
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="dialogType_dept == 'ADD' ? '新增部门' : '修改部门'"
      :visible.sync="dialogVisible_dept"
      width="35%"
      @closed="treeShow = false"
    >
      <div class="dialog-body">
        <ul>
          <li>
            <span>
              <i>*</i>
              上级部门:
            </span>
            <p class="parentBox">
              <!-- <el-input
                v-if="deptData_param_parent=='org'"
                v-model="deptData_param.org_id"
                :disabled="dialogType_dept == 'EDIT'"
                size="mini"
                placeholder="请输入内容"
              ></el-input>
              <el-input
                v-else
                v-model="deptData_param.parent_dept_id"
                :disabled="dialogType_dept == 'EDIT'"
                size="mini"
                placeholder="请输入内容"
              ></el-input> -->
              <el-input
                ref="parentInpt"
                v-model="deptData_param_parent.lable"
                :disabled="dialogType_dept == 'EDIT'"
                size="mini"
                placeholder="请输入上级部门"
                @input="filter_org"
                @focus="parentFocus()"
                @blur="parentBlur"
              ></el-input>
              <span class="treeBox" v-show="treeShow" @click="handleNode">
                <el-tree
                  node-key="pk"
                  :data="organizations"
                  :props="defaultProps"
                  :highlight-current="true"
                  :expand-on-click-node="false"
                  ref="orgTree"
                  @node-click="handleNodeClick"
                  @node-expand="handleNode"
                  @node-collapse="handleNode"
                ></el-tree>
              </span>
            </p>
          </li>
          <li>
            <span> <i>*</i>部门名称:</span>
            <p>
              <el-input
                v-model="deptData_param.dept_name"
                size="mini"
                placeholder="请输入部门名称"
              ></el-input>
            </p>
          </li>
          <li>
            <span> <i>*</i>显示顺序:</span>
            <p>
              <el-input
                v-model="deptData_param.priority"
                size="mini"
                placeholder="请输入显示顺序"
              ></el-input>
            </p>
          </li>
          <li>
            <span>负责人:</span>
            <p>
              <el-input
                v-model="deptData_param.owner"
                size="mini"
                placeholder="请输入负责人"
              ></el-input>
            </p>
          </li>
          <li>
            <span>联系电话:</span>
            <p>
              <el-input
                v-model="deptData_param.phone"
                size="mini"
                placeholder="请输入联系电话"
              ></el-input>
            </p>
          </li>
          <li>
            <span>联系邮箱:</span>
            <p>
              <el-input
                v-model="deptData_param.email"
                size="mini"
                placeholder="请输入联系邮箱"
              ></el-input>
            </p>
          </li>
          <li>
            <span><i>*</i>部门状态:</span>
            <p>
              <el-radio v-model="deptData_param.is_active" :label="true">正常</el-radio>
              <el-radio v-model="deptData_param.is_active" :label="false">停用</el-radio>
            </p>
          </li>
        </ul>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="dialogType_dept == 'ADD' ? add_system_dept():put_system_dept()"
          size="small"
        >
          确 定
        </el-button>
        <el-button @click="dialogVisible_dept = false" size="small">
          取 消
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import manageMixins from './manageMixins.js'
import api from '@/api/manage.js'
import { flexColumnWidth } from '@/assets/js/adaption.js'
export default {
  mixins: [manageMixins],
  props: [ 'orgList'],
  data() {
    return {
      disabled_org_add: false,
      disabled_org_edit: true,
      search_data_org: {
        org_title: '',
        is_active: '',
        page: 1,
        size: 10,
      },
      option_zt: [
        {
          value: true,
          label: '正常'
        },{
          value: false,
          label: '停用'
        }
      ],
      option_yydj: [
        {
          value: 1,
          label: '民营'
        },{
          value: 2,
          label: '卫生院'
        },
        {
          value: 3,
          label: '一甲'
        },{
          value: 4,
          label: '一乙'
        },
        {
          value: 5,
          label: '一丙'
        },{
          value: 6,
          label: '二甲'
        },
        {
          value: 7,
          label: '二乙'
        },{
          value: 8,
          label: '二丙'
        },
        { 
          value: 9,
          label: '三特'
        },{
          value: 10,
          label: '三甲'
        },
        {
          value: 11,
          label: '三乙'
        },{
          value: 12,
          label: '三丙'
        },
      ],
      option_sf: [],
      option_dq: [],
      total: 0,
      orgData_param: {
        org_title: '',
        org_master: '',
        org_email: '',
        org_phone: '',
        priority: '',
        city_id: '',
        is_active: true,
      },
      deptData_param: {
        dept_name: '',
        parent_dept_id: '',
        org_type: 1,
        level_id: '',
        province_id: '',
        city_id: '',
        org_address: '',
        org_id: '',
        priority: '',
        owner: '',
        phone: '',
        is_active: true,
        email: '',
      },
      // orgList: [],
      organizations: [],
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      treeClick: false,
      treeShow: false,
      parent_copy: true,
      deptData_param_parent: {},
      row_orgData: {},
      tableData_org: [],
      loading_org: true,
      dialogType_org: 'ADD',
      dialogType_dept: 'ADD',
      dialogVisible_org: false,
      dialogVisible_dept: false,
    }
  },
  created() {
    this.get_system_org()
    // this.get_system_org_all()
    this.option_sf = JSON.parse(localStorage.getItem('province'))
  },
  methods: {
    // 宽度
    flexColumnWidth(width, minWidth) {
      return flexColumnWidth(width, minWidth)
    },
    // 选择框
    selectionchange_org(selection) {
      this.row_orgData = {}
      if (selection.length == 0) {
        this.disabled_org_add = false
        this.disabled_org_edit = true
        return
      }
      if (selection.length == 1) {
        this.row_orgData = selection[0]
        this.disabled_org_add = false
        this.disabled_org_edit = false
      } else {
        this.disabled_org_add = true
        this.disabled_org_edit = true
      }
    },
    // 切换省份
    set_sf(value) {
      this.orgData_param.city_id = ''
      if(value){
        this.option_dq = this.option_sf.find(e => e.value === value).city_list
      }
    },
    // 打开新增机构弹窗
    open_addOrg_dialog(row = this.row_orgData) {
      // 新增机构
      if (!row.name) {
        this.dialogType_org = 'ADD'
        this.dialogVisible_org = true
        this.orgData_param = {
          org_title: '',
          org_type: 1,
          level_id: '',
          province_id: '',
          city_id: '',
          org_address: '',
          org_master: '',
          org_email: '',
          org_phone: '',
          priority: '',
          is_active: true,
        }
        return
      }
      this.dialogType_dept = 'ADD'
      this.dialogVisible_dept = true
      this.deptData_param = {
        dept_name: '',
        parent_dept_id: '',
        org_id: '',
        priority: '',
        owner: '',
        phone: '',
        is_active: true,
        email: '',
      }
      if (row.data_flag == 'org') { //新增顶级部门
        this.deptData_param_parent = {
          type: 'org',
          id: row.pk,
          org_id: row.pk,
          lable: row.name,
        }
      } else if (row.data_flag == 'dept') { //新增次级部门
        this.deptData_param_parent = {
          type: 'dept',
          id: row.pk,
          org_id: row.org_id,
          lable: row.name,
        }
      }
    },
    // 打开修改机构弹窗
    open_editOrg_dialog(row = this.row_orgData) {
      if (row.data_flag == 'org') { //修改机构
        this.dialogType_org = 'EDIT'
        this.dialogVisible_org = true
        this.orgData_param = {
          org_title: row.name,
          org_type: row.level_id?1:2,
          city_id: '',
          org_address: row.org_address,
          org_master: row.org_master,
          org_email: row.org_email,
          org_phone: row.org_phone,
          priority: row.priority,
          org_id: row.org_id,
          is_active: row.is_active,
          province_id: row.province_id,

        }
        if(row.level_id){
          this.orgData_param = {
            ...this.orgData_param,
            org_type: 1,
            level_id: row.level_id,
          }
        }else {
          this.orgData_param.org_type = 2;
        }
        this.set_sf(row.province_id)
        this.orgData_param.city_id = row.city_id;
      } else { //修改部门
        this.dialogType_dept = 'EDIT'
        this.deptData_param_parent.lable = row.parent_dept_name||row.org_title
        this.dialogVisible_dept = true
        this.deptData_param = {
          dept_id: row.pk,
          dept_name: row.name,
          parent_dept_id: row.parent_dept_id,
          org_id: row.org_id,
          priority: row.priority,
          owner: row.owner,
          phone: row.phone,
          is_active: row.is_active,
          email: row.email,
        }
      }
    },
    // 获取系统管理组织机构架构列表
    get_system_org() {
      this.loading_org = true
      api
        .system_org(this.search_data_org)
        .then((res) => {
          this.tableData_org = res.data.data
          this.total = res.data.count
        })
        .finally((msg) => {
          this.loading_org = false
        })
    },
    // 获取系统管理组织机构架构列表全列表
    get_system_org_all() {
      api
        .system_org({ size: 'all' })
        .then((res) => {
          this.orgList = res.data
          this.filter_org()
        })
        .finally((msg) => {})
    },
    // 组织名称筛选
    filter_org(value) {
      this.organizations = this.orgList.filter(
        (e) => e.name.search(value) != -1,
      )
    },
    // 点击确认某个机构或部门
    handleNodeClick(data) {
      this.deptData_param_parent = {
        type: data.data_flag,
        id: data.pk,
        lable: data.name,
        org_id: data.data_flag=='org'?data.pk:data.org_id,
      }
      this.old_deptData_param_parent = JSON.parse(JSON.stringify(this.deptData_param_parent))
      this.treeShow = false;
    },
    // 失去焦点事件，判断是否由于点击树框内元素导致的失去焦点
    parentBlur() {
      setTimeout(() => { 
        if(this.treeClick){ //点击树框导致 再获取焦点
          this.parent_copy = false
          this.$refs.parentInpt.focus();
        }else {
          this.treeShow = false;
          this.deptData_param_parent = this.old_deptData_param_parent
        }
      }, 200);
    },
    // 获得焦点事件
    parentFocus() {
      if(this.parent_copy){ //手动获取，则不保存当前的数据
        this.old_deptData_param_parent = JSON.parse(JSON.stringify(this.deptData_param_parent))
        this.filter_org();
      }
      this.parent_copy = true;
      this.treeShow = true;
    },
    // 点击框内触发
    handleNode(){
      this.treeClick = true;
      setTimeout(() => {
        this.treeClick = false;
      }, 300);
    },
    // 新增机构
    add_system_org() {
      if(this.orgData_param.org_type===1){
        if (this.testParams(this.orgData_param, ['org_title','org_address','level_id','province_id','city_id'],true) == false) {
          return
        }
      }else {
        if (this.testParams(this.orgData_param, ['org_title','org_address','org_type'],true) == false) {
          return
        }
      }
      this.loadingShow('新增中')
      api
        .system_org_add(this.orgData_param)
        .then((res) => {
          this.$message.success('新增成功')
          this.dialogVisible_org = false
          this.get_system_org()
          this.$parent.get_system_org_all();
        })
        .finally((msg) => {
          this.loading.close()
        })
    },
    // 修改机构
    put_system_org() {
      if(this.orgData_param.org_type===1){
        if (this.testParams(this.orgData_param, ['org_title','org_address','level_id','province_id','city_id']) == false) {
          return
        }
      }else {
        if (this.testParams(this.orgData_param, ['org_title','org_address','org_type']) == false) {
          return
        }
      }
      this.loadingShow('修改')
      api
        .system_org_put(this.orgData_param)
        .then((res) => {
          this.$message.success('修改成功')
          this.dialogVisible_org = false
          this.get_system_org()
          this.$parent.get_system_org_all();
        })
        .finally((msg) => {
          this.loading.close()
        })
    },
    // 删除机构
    del_system_org(org_id_list) {
      this.loadingShow('删除中')
      api
        .system_org_delete({ org_id_list })
        .then((res) => {
          this.$message.success('删除成功')
          this.get_system_org()
          this.$parent.get_system_org_all();

        })
        .finally((msg) => {
          this.loading.close()
        })
    },
    // 新增部门
    add_system_dept() {
      this.deptData_param.org_id = this.deptData_param_parent.org_id;
      if(this.deptData_param_parent.type=='dept') {
        this.deptData_param.parent_dept_id = this.deptData_param_parent.id
      }
      if (
        this.testParams(this.deptData_param, ['dept_name', 'priority','is_active'],true) == false
      ) {
        return
      }
      // delete this.deptData_param.parent_dept_id
      // console.log(this.deptData_param);
      // return

      this.loadingShow('新增中')
      api
        .system_dept_add(this.deptData_param)
        .then((res) => {
          this.$message.success('新增成功')
          this.dialogVisible_dept = false
          this.get_system_org()
          // this.get_system_org_all()
          this.$parent.get_system_org_all();
        }).catch(err => {
          this.loading.close()
        })
       
    },
    // 修改部门
    put_system_dept() {
      if (
        this.testParams(this.deptData_param, ['dept_name', 'priority']) == false
      ) {
        return
      }
      this.loadingShow('修改中')
      api
        .system_dept_put(this.deptData_param)
        .then((res) => {
          this.$message.success('修改成功')
          this.dialogVisible_dept = false
          this.get_system_org()
          this.$parent.get_system_org_all();
        })
        .finally((msg) => {
          this.loading.close()
        })
    },
    // 删除部门
    del_system_dept(dept_id_list) {
      this.loadingShow('删除中')
      api
        .system_dept_delete({ dept_id_list })
        .then((res) => {
          this.$message.success('删除成功')
          this.get_system_org()
          this.$parent.get_system_org_all();
        })
        .finally((msg) => {
          this.loading.close()
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.orgManage {
  width: 100%;
}
.body {
  width: 100%;
}
.right-top {
  background-color: #fff;
  padding: px(10);
  border-radius: px(8);
  ul {
    display: flex;
    align-items: center;
    li {
      display: flex;
      width: 25%;
      align-items: center;
      &>span{
        width: 30%;
      }
    }
    li:last-child {
      margin-left: px(20);
    }
  }
}
.right-body {
  margin-top: px(10);
  background-color: #fff;
  padding: px(10);
  border-radius: px(8);
  .btn-box {
  }
}
.dialog-body {
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      width: 90%;
      display: flex;
      margin-bottom: px(10);
      //align-items: center;
      span {
        width: 30%;
        text-align: right;
        margin-right: px(10);
        i {
          color: red;
          margin-right: px(5);
        }
      }
      & > p {
        width: 80%;
      }
    }
  }
}
.parentBox {
  position: relative;
  .treeBox {
    width: 100%;
    height: px(200);
    top: 35px;
    left: 0;
    position: absolute;
    overflow: auto;
    z-index: 99;
    background-color: #fefefe;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  }

}

.state-box {
  border-radius: px(18);
  padding: px(5) px(10);
  color: #fff;
  
}
</style>
