export default {
  data() {
    return {
      loading: '',
    }
  },
  methods: {
      // 发送前校验与空值去除,优先级转数字
    testParams(obj,arr,isDel = false) {
      for(let e of arr){
        if(obj[e]===''||obj[e]===null){
          this.$message.warning('*为必填项，请填写完整')
          return false
        }
      }
      if(isDel) {
        for(let key in obj){
        if(obj[key] === ''||obj[key]===null){
          delete obj[key];
        }
      }
      }
      
      obj.priority?obj.priority=Number(obj.priority):''

    },
    // 提示弹窗
    loadingShow(text) {
      this.loading = this.$loading({
        lock: true,
        text,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
    },
  }
}
