<template>
  <div class="userManage">
    <div v-show="bodyType == 'USER'">
      <div class="left">
        <div class="left-top">
          <p>组织架构</p>
          <p class="edit-box">
            <i class="el-icon-edit" @click="bodyType = 'ORGANIZATION'"></i>
            <i class="el-icon-refresh" @click="refresh_userData"></i>
          </p>
        </div>
        <el-divider class="divider"></el-divider>
        <div class="left-body">
          <div class="left-top">
            <el-input
              v-model="orgInput"
              size="mini"
              placeholder="请输入组织名称查询"
            ></el-input>
            <el-button type="primary" size="mini" @click="filter_org_tree">
              搜索
            </el-button>
          </div>
          <div class="left-content">
            <el-tree
              node-key="pk"
              :data="organizations"
              :props="defaultProps"
              :highlight-current="true"
              :filter-node-method="filterNode"
              ref="orgTree"
              v-loading="treeLoading"
              @node-click="handleNodeClick"
            ></el-tree>
          </div>
        </div>
      </div>

      <div class="right">
        <div class="right-top">
          <ul>
            <li>
              登录名称:
              <el-input
                v-model="search_data_user.user_name"
                size="mini"
                placeholder="请输入登录名称"
              ></el-input>
            </li>
            <li>
              手机号码:
              <el-input
                v-model="search_data_user.phone"
                size="mini"
                placeholder="请输入手机号码"
              ></el-input>
            </li>
            <li class="li-timePick-box">
              创建时间:
              <timePickerCloud
                ref="timePickerCloud"
                :pickerOptions="pickerOptions"
                :end_stats_dayOld="search_data_user.end_time"
                :start_stats_dayOld="search_data_user.start_time"
                @setTime="setTime"
              ></timePickerCloud>
            </li>
            <li class="li-ben-box">
              <el-button
                type="primary"
                size="mini"
                @click="search_get_system_user"
              >
                搜索
              </el-button>
            </li>
          </ul>
        </div>
        <div class="right-body">
          <div class="btn-box">
            <el-button type="primary" size="mini" @click="open_addUser_dialog">
              新增
            </el-button>
            <el-button
              type="warning"
              size="mini"
              @click="open_editUser_dialog()"
              :disabled="disabled_user_edit"
            >
              修改
            </el-button>
            <el-popconfirm
              confirm-button-text="好的"
              cancel-button-text="不用了"
              icon="el-icon-info"
              icon-color="red"
              :title="
                data_delUser.length == 0
                  ? '确定删除该用户吗？'
                  : '确定删除这些用户吗？'
              "
              @confirm="del_system_user(data_delUser)"
            >
              <el-button
                slot="reference"
                type="danger"
                style="margin: 0 10px"
                size="mini"
                :disabled="disabled_user_del"
              >
                删除
              </el-button>
            </el-popconfirm>
          </div>
          <div>
            <el-table
              style="margin-bottom: 20px"
              :data="tableData_user"
              header-align="center"
              v-loading="loading_user"
              stripe
              size="small"
              @selection-change="selection_change_user"
            >
              <el-table-column
                align="center"
                type="selection"
                width="55"
              ></el-table-column>
              <el-table-column
                prop="user_id"
                align="center"
                label="用户ID"
                :width="flexColumnWidth(100)"
              ></el-table-column>
              <el-table-column
                prop="user_name"
                align="center"
                label="登录名称"
                :width="flexColumnWidth(130)"
              ></el-table-column>
              <el-table-column
                prop="phone"
                align="center"
                label="手机号码"
                :width="flexColumnWidth(130)"
              ></el-table-column>
              <el-table-column
                prop="create_time"
                align="center"
                label="创建时间"
                :width="flexColumnWidth(180)"
              ></el-table-column>

              <el-table-column prop="address" align="center" label="操作">
                <template slot-scope="scope">
                  <div class="caozuo">
                    <el-button
                      type="warning"
                      size="mini"
                      @click="open_editUser_dialog(scope.row)"
                    >
                      编辑
                    </el-button>
                    <el-popconfirm
                      confirm-button-text="好的"
                      cancel-button-text="不用了"
                      icon="el-icon-info"
                      icon-color="red"
                      title="确认删除该用户吗？"
                      @confirm="del_system_user([scope.row.user_id])"
                    >
                      <el-button
                        slot="reference"
                        type="danger"
                        style="margin: 0 10px"
                        size="mini"
                      >
                        删除
                      </el-button>
                    </el-popconfirm>
                    <el-button size="mini" @click="openPawDialog(scope.row)">
                      重置密码
                    </el-button>
                    <el-button size="mini" @click="openroleDialog(scope.row)">
                      分配角色
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              :hide-on-single-page="total <= 0"
              background
              layout="total,prev, pager, next,jumper"
              :total="total_user"
              :page-size="search_data_user.size"
              :current-page.sync="search_data_user.page"
              @current-change="get_system_user"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-show="bodyType == 'ORGANIZATION'">
      <orgManage :orgList="orgList"></orgManage>
    </div>
    <el-dialog title="重置密码" :visible.sync="dialogVisible_pwd" width="30%" :style="{ 'max-height': '460px' }" >
      <div class="dialog-pow-body">
        <ul>
          <li>
            <span>输入密码:</span>
            <p>
              <el-input
                v-model="resetUserPwd_param.passwd"
                size="mini"
                placeholder="请输入密码"
                :key="pwKey1"
                @keyup.enter.native="handleSubmit1()"
                @input="pwInput1"
                :type="pwType1"
              ></el-input>
            </p>
          </li>
          <li>
            <span>再次输入密码:</span>
            <p>
              <el-input
                v-model="resetUserPwd_param.passwd_two"
                size="mini"
                placeholder="请再次输入密码"
                :key="pwKey2"
                @keyup.enter.native="handleSubmit2()"
                @input="pwInput2"
                :type="pwType2"
              ></el-input>
            </p>
          </li>
        </ul>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="reset_system_user_pwd" size="small">
          确 定
        </el-button>
        <el-button @click="dialogVisible_pwd = false" size="small">
          取 消
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="用户分配角色"
      :visible.sync="dialogVisible_role"
      width="50%"
      custom-class="dialog_global"
      :style="{ 'max-height': '700px' }"
    >
      <div class="dialog-role-body">
        <TJDetailTitle titleData="基本信息" />
        <TJDetailMenu :dataList="dataList" />
        <TJDetailTitle titleData="分配角色" />
        <el-table
          style="margin-bottom: 20px"
          :data="tableData_role"
          header-align="center"
          stripe
          size="small"
          @row-click="singleElection"
          highlight-current-row
          height="300"
        >
          <el-table-column align="center" width="60">
            <template slot-scope="scope">
              <el-radio
                class="radio"
                v-model="userData.role_id"
                :label="scope.row.role_id"
              >
                &nbsp;
              </el-radio>
              <!-- 可以手动的修改label的值，从而控制选择哪一项 -->
            </template>
          </el-table-column>

          <el-table-column
            prop="role_id"
            align="center"
            label="角色ID"
          ></el-table-column>
          <el-table-column
            prop="role_title"
            align="center"
            label="角色名称"
          ></el-table-column>
          <el-table-column
            prop="create_time"
            align="center"
            label="创建时间"
          ></el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="put_system_user(userData,'role_id')">
          确 定
        </el-button>
        <el-button @click="dialogVisible_role = false" size="small">
          取 消
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="dialogType == 'ADD' ? '新增用户' : '修改用户'"
      :visible.sync="dialogVisible_addUser"
      width="50%"
      :style="{ 'max-height': '640px' }"
    >
      <div class="dialog-user-body">
        <ul>
          <li>
            <span>
              <i>*</i>
              用户名称:
            </span>
            <p>
              <el-input
                v-model="userData_param.user_name"
                size="mini"
                placeholder="请输入内容"
              ></el-input>
            </p>
          </li>
          <li>
            <span>
              <i>*</i>
              归属机构:
            </span>
            <p>
              <el-select
                class="langInput"
                v-model="userData_param.organization_id"
                filterable
                @clear="set_deepList('')"
                @change="set_deepList"
                size="mini"
                placeholder="请选择归属机构"
                clearable
              >
                <el-option
                  v-for="item in orgList"
                  :key="item.org_id"
                  :label="item.name"
                  :value="item.org_id"
                ></el-option>
              </el-select>
            </p>
          </li>
          <li>
            <span>
              <i>*</i>
              归属部门:
            </span>
            <p>
              <el-select
                class="langInput"
                v-model="userData_param.dept_id"
                size="mini"
                :disabled="deepList.length == 0 ? true : false"
                :placeholder="
                  deepList.length == 0 ? '请先选择机构或添加部门' : '请选择部门'
                "
                clearable
              >
                <el-option
                  v-for="item in deepList"
                  :key="item.pk"
                  :label="item.name"
                  :value="item.pk"
                ></el-option>
              </el-select>
            </p>
          </li>
          <li>
            <span>
              <i>*</i>
              职位:
            </span>
            <p>
              <el-select
                class="langInput"
                v-model="userData_param.post_id"
                filterable
                size="mini"
                placeholder="请选择职位"
                clearable
              >
                <el-option
                  v-for="item in postList"
                  :key="item.post_id"
                  :label="item.post_title"
                  :value="item.post_id"
                ></el-option>
              </el-select>
            </p>
          </li>
          <li>
            <span>
              <i>*</i>
              手机号码:
            </span>
            <p>
              <el-input
                v-model="userData_param.phone"
                size="mini"
                placeholder="请输入手机号码"
              ></el-input>
            </p>
          </li>
          <li v-if="dialogType == 'ADD'">
            <span>
              <i>*</i>
              登录密码:
            </span>
            <p>
              <el-input
                v-model="userData_param.passwd"
                size="mini"
                placeholder="请输入登录密码"
              ></el-input>
            </p>
          </li>
          <li>
            <span>
              <i>*</i>
              分配角色:
            </span>
            <p>
              <el-select
                v-model="userData_param.role_id"
                filterable
                size="mini"
                placeholder="请选择"
                :default-first-option="tableData_role.length==0"
                clearable
                @focus="get_system_role(userData_param.organization_id)"
              >
                <el-option
                  v-for="item in tableData_role"
                  :key="item.role_id"
                  :label="item.role_title"
                  :value="item.role_id"
                ></el-option>
              </el-select>
              <!-- <el-radio v-for="(item,index) in tableData_role" :key="index" v-model="userData_param.role_id" :label="item.role_id"  v-show="index<5">
                {{ item.role_title }}
              </el-radio> -->
            </p>
          </li>
          <li>
            <span>性别:</span>
            <p>
              <el-radio v-model="userData_param.choice_gender" :label="1">
                男
              </el-radio>
              <el-radio v-model="userData_param.choice_gender" :label="2">
                女
              </el-radio>
            </p>
          </li>
        </ul>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="
            dialogType == 'ADD'
              ? add_system_user()
              : put_system_user(userData_param)
          "
          size="small"
        >
          确 定
        </el-button>
        <el-button @click="dialogVisible_addUser = false" size="small">
          取 消
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import timePickerCloud from '@/components/timePickerCloud.vue'
import TJDetailTitle from '@/components/TJDetailTitle'
import TJDetailMenu from '@/components/TJDetailMenu'
import orgManage from './components/orgManage'
import { flexColumnWidth } from '@/assets/js/adaption.js'
import api from '@/api/manage.js'
import manageMixins from './components/manageMixins.js'
import { validateMobile } from '@/assets/js/validate.js'

export default {
  components: { timePickerCloud, TJDetailTitle, TJDetailMenu, orgManage },
  mixins: [manageMixins],

  data() {
    return {
      bodyType: 'USER',
      pickerOptions: {},
      loading: '',
      pwKey1: 0,
      pwType1: 'text',
      pwKey2: 0,
      pwType2: 'text',

      disabled_user_edit: true,
      disabled_user_del: true,
      data_delUser: [],
      data_editUser: {},
      dialog_userData: {},
      total_user: 0,
      loading_user: true,
      search_data_user: {
        user_name: '',
        phone: '',
        organization_id: '',
        start_time: '',
        end_time: '',
        page: 1,
        size: 10,
      },
      total: 100,
      userData_param: {
        phone: '',
        passwd: '',
        user_name: '',
        dept_id: '',
        post_id: '',
        choice_gender: 1,
        role_id: '',
      },
      search_data_org: {
        org_title: '',
        org_state: '',
      },
      orgData_param: {
        org_title: '',
        org_master: '',
        org_email: '',
        org_phone: '',
        priority: '',
      },
      dialogType_org: '',
      dialogType: '',

      resetUserPwd_param: {
        user_id: '',
        passwd: '',
        passwd_two: '',
      },
      userData: {},
      tableData_role: [],
      orgList: [],
      deepList: [],
      postList: [],
      orgInput: '',
      organizations: [],
      organizations2: [
        {
          name: '上海奥普生物技术有限公司',
          children: [
            {
              name: '大市场中心',
              children: [
                {
                  name: '大数据部',
                },
                {
                  name: '后勤部',
                },
              ],
            },
          ],
        },
        {
          name: '国药意满园',
          children: [
            {
              name: '采购部',
            },
          ],
        },
        {
          name: '上海浦东医院',
          children: [
            {
              name: '检验科',
            },
          ],
        },
      ],
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      pageNum: 1,
      tableData_user: [],

      dataList: [
        {
          name: '用户名称',
          value: '',
          width: '25%',
        },
        {
          name: '登录账号',
          value: '',
          width: '25%',
        },
      ],
      treeLoading: false,
      dialogVisible_pwd: false,
      dialogVisible_role: false,

      dialogVisible_addUser: false,
      title: '',
      dialogVisible_org: false,
      radio: '1',

      organizations_input: '',
      treeShow: false,
      parent_copy: true,
      old_userData_param: '',
    }
  },
  created() {
    this.get_system_user()
    // this.get_system_role()
    this.get_system_org_all();
    this.get_system_post();
  },

  mounted() {
    this.$refs.timePickerCloud.setTime('', '')
    this.search_data_user.start_time = ''
    this.search_data_user.end_time = ''
  },

  methods: {
    // 宽度
    flexColumnWidth(width, minWidth) {
      return flexColumnWidth(width, minWidth)
    },
    // 获取角色列表
    get_system_role(org_id) {
      if(org_id){
        api.system_role({ org_id,page:1,size:100 }).then((res) => {
          this.tableData_role = res.data.data
        })
      }else{
        this.$message.success('请先选择机构')
      }
      
    },

    // 角色选择
    singleElection(row) {
      this.userData.role_id = row.role_id
    },
    // 选择某组织机构
    handleNodeClick(data) {
      this.search_data_user.page = 1
      this.search_data_user.organization_id = data.org_id
      if(data.org_id!=data.pk){
        this.search_data_user.dept_id = data.pk
      }else {
        this.search_data_user.dept_id = ''
      }
      this.get_system_user()
    },

    // 刷新用户数据且清除单位选择
    refresh_userData() {
      this.$refs.orgTree.setCurrentKey()
      this.search_data_user.page = 1
      this.search_data_user.organization_id = ''
      this.search_data_user.dept_id = ''
      this.get_system_user()
    },
    // 重置密码弹窗
    openPawDialog(row) {
      this.resetUserPwd_param = {
        user_id: row.user_id,
        passwd: '',
        passwd_two: '',
      }
      this.dialogVisible_pwd = true
    },
    // 修改input输入框的type属性
    pwInput1(e) {
      if (e) {
        this.pwType1 = 'password'
      } else {
        this.pwKey1++
        this.pwType1 = 'text'
      }
    },
    pwInput2(e) {
      if (e) {
        this.pwType2 = 'password'
      } else {
        this.pwKey2++
        this.pwType2 = 'text'
      }
    },
    // 按钮：确认
    handleSubmit1() {
      this.$nextTick(() => {
        this.pwInput1()
      }, 100)
    },
    handleSubmit2() {
      this.$nextTick(() => {
        this.pwInput2()
      }, 100)
    },
    // 重置密码
    reset_system_user_pwd() {
      if (
        this.resetUserPwd_param.passwd == '' ||
        this.resetUserPwd_param.passwd != this.resetUserPwd_param.passwd_two
      ) {
        this.$message.warning('请填写完整密码数据并保证两次密码相同')
        return
      }
      this.loadingShow('修改中')
      api
        .system_user_reset_passwd(this.resetUserPwd_param)
        .then((res) => {
          this.dialogVisible_pwd = false
          this.$message.success('修改成功')
          this.resetUserPwd_param = {
            user_id: '',
            passwd: '',
            passwd_two: '',
          }
          this.$nextTick(() => {
            this.pwInput1()
            this.pwInput2()
          })
        })
        .finally((msg) => {
          this.loading.close()
        })
    },
    // 打开分配角色弹窗
    openroleDialog(row) {
      this.dataList[0].value = row.user_name
      this.dataList[1].value = row.phone
      this.userData = {
        user_id: row.user_id,
        role_id: row.role_id,
      }
      this.get_system_role(row.organization_id);
      this.dialogVisible_role = true
    },

    // 打开新增用户弹窗
    open_addUser_dialog() {
      this.dialogType = 'ADD'
      this.userData_param = {
        phone: '',
        passwd: '',
        user_name: '',
        post_id: '',
        organization_id: this.search_data_user.organization_id,
        dept_id: this.search_data_user.dept_id,
        choice_gender: 1,
        role_id: '',
      }
      this.set_deepList(this.search_data_user.organization_id,this.search_data_user.dept_id);
      this.dialogVisible_addUser = true
    },
    // 打开编辑用户弹窗
    open_editUser_dialog(row = this.data_editUser) {
      this.dialogType = 'EDIT'
      this.dialogVisible_addUser = true
      // this.userData_param = row;
      this.userData_param = {
        user_id: row.user_id,
        user_name: row.user_name,
        phone: row.phone,
        post_id: row.post_id,
        organization_id: row.organization_id,
        dept_id: row.dept_id,
        choice_gender: row.gender,
        role_id: row.role_id,
      }
      this.get_system_role(row.organization_id);

      this.set_deepList(row.organization_id,row.dept_id);
      // api.system_user_info({ user_id: row.user_id }).then((res) => {})
    },

    // 用户选择
    selection_change_user(selection) {
      if (selection.length == 0) {
        this.disabled_user_edit = true
        this.disabled_user_del = true
        return
      }
      if (selection.length == 1) {
        this.data_editUser = selection[0]
        this.disabled_user_edit = false
      } else {
        this.disabled_user_edit = true
      }
      this.disabled_user_del = false
      this.data_delUser = selection.map((e) => e.user_id)
    },
    // 获取系统职位信息
    get_system_post() {
      api.system_post().then(res => {
        this.postList = res.data;
      })
    },
    // 点击搜索用户
    search_get_system_user() {
      this.search_data_user.page = 1
      this.get_system_user()
    },
    // 获取系统管理组织机构架构列表全列表
    get_system_org_all() {
      this.treeLoading = true
      this.loadingShow('加载中')
      api
        .system_org({ size: 'all' })
        .then((res) => {
          this.orgList = res.data
          // this.filter_org()
          /* user页处理 */
          this.organizations = this.orgList
          this.filter_org_tree()
          this.filter_org()
         
        })
        .finally((msg) => { this.treeLoading = false
          this.loading.close()})
    },
    
    filterNode(value, data,node) {
      if (!value) return true
      let a = false
      const getParent=(node)=>{
        if(node.level>1){
          getParent(node.parent)
        }else{
          a = node.data.name.indexOf(value) !== -1
        }
      }
      getParent(node)
      return a
    },
    // 组织名称筛选
    filter_org_tree() {
      this.$refs.orgTree.filter(this.orgInput)
    },
    // 异步等待函数
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },
    async asyncFunction() {
      for (let i = 0; i < this.orgList; i += 100) {
        chunk = arr.slice(i, i + split_len)
        this.organizations.push(...chunk)
        await this.delay(100)
      }
    },
    // 获取用户列表
    get_system_user() {
      this.loading_user = true
      api
        .system_user(this.search_data_user)
        .then((res) => {
          this.tableData_user = res.data.data
          this.total_user = res.data.count
        })
        .finally((msg) => {
          this.loading_user = false
        })
    },
    // 获取用户详细信息
    get_system_user_info() {
      api.system_user_info().then((res) => {
      })
    },
    // 新增用户
    add_system_user() {
      if(!validateMobile(this.userData_param.phone)){
        return
      }
      if (
        this.testParams(this.userData_param, [
          'user_name',
          'organization_id',
          'dept_id',
          'phone',
          'passwd',
          'role_id',
        ],true) == false
      ) {
        return
      }
      this.loadingShow('新增中')
      api
        .system_user_add(this.userData_param)
        .then((res) => {
          this.dialogVisible_addUser = false
          this.$message.success('新增成功')
          this.get_system_user()
        })
        .finally((msg) => {
          this.loading.close()
        })
    },
    // 修改用户
    put_system_user(userData_param = this.userData,type='') {
      if(userData_param.phone&&!validateMobile(userData_param.phone)){
        return
      }
      let testParamsList = type === ''? ['user_name',
          'organization_id',
          'dept_id',
          'phone',
          'role_id',]:['role_id']
      if ( this.testParams(userData_param, testParamsList) == false) {
        return
      }
      this.loadingShow('修改中')
      api
        .system_user_put(userData_param)
        .then((res) => {
          this.dialogVisible_role = false
          this.$message.success('修改成功')
          this.get_system_user()
        })
        .finally((msg) => {
          this.loading.close()
        })
    },
    // 删除用户
    del_system_user(user_list) {
      this.loadingShow('删除中')
      api
        .system_user_delete({ user_list })
        .then((res) => {
          this.$message.success('删除成功')
          this.get_system_user()
        })
        .finally((msg) => {
          this.loading.close()
        })
    },
    // 设置时间
    setTime(val) {
      this.search_data_user.start_time = val.start_stats_day
        ? val.start_stats_day.substring(0, 4) +
          '-' +
          val.start_stats_day.substring(4, 6) +
          '-' +
          val.start_stats_day.substring(6, 8)
        : ''
      this.search_data_user.end_time = val.end_stats_day
        ? val.end_stats_day.substring(0, 4) +
          '-' +
          val.end_stats_day.substring(4, 6) +
          '-' +
          val.end_stats_day.substring(6, 8)
        : ''
      // this.set_time_and_phOption('time');
    },

    // 组织名称筛选
    filter_org(value) {
      this.organizations_input = this.orgList.filter(
        (e) => e.name.search(value) != -1,
      )
    },
    // 获得焦点事件
    parentFocus() {
      if (this.parent_copy) {
        //手动获取，则不保存当前的数据
        this.old_userData_param = JSON.parse(
          JSON.stringify(this.userData_param),
        )
      }
      this.parent_copy = true
      this.treeShow = true
    },
    // 失去焦点事件，判断是否由于点击树框内元素导致的失去焦点
    parentBlur() {
      setTimeout(() => {
        if (this.treeClick) {
          //点击树框导致 再获取焦点
          this.parent_copy = false
          this.$refs.parentInpt.focus()
        } else {
          this.treeShow = false
          this.userData_param = this.old_userData_param
        }
      }, 200)
    },
    // 根据确认的机构查询部门信息
    set_deepList(org_id,dept_id='') {
      this.userData_param.dept_id = dept_id
      if(org_id){
        this.deepList = this.orgList.find((e) => e.org_id == org_id).children
      }else {
        this.deepList = [];
      }
    },
    // 点击确认某个机构或部门
    // handleNodeClick_input(data) {
    //   if(data.data_flag=='org'){
    //     this.treeClick = true;
    //     setTimeout(() => {
    //       this.treeClick = false;
    //     }, 300);
    //   }else {
    //     this.userData_param.dept_name = data.name
    //     this.userData_param.dept_id = data.pk
    //     this.old_userData_param = JSON.parse(JSON.stringify(this.userData_param))

    //   }
    // },
    // 点击框内触发
    handleNode() {
      this.treeClick = true
      setTimeout(() => {
        this.treeClick = false
      }, 300)
    },
  },
}
</script>

<style lang="scss" scoped>
.userManage {
  & > div {
    display: flex;
    justify-content: space-between;
    margin: 10px 20px;
  }
}
.left {
  width: 18%;
}
.left-top {
  display: flex;
  justify-content: space-between;
  .edit-box {
    & > i {
      margin-right: px(10);
    }
  }
}
.right {
  width: 80%;
}
.body {
  width: 100%;
}
.right-top {
  background-color: #fff;
  padding: px(10);
  border-radius: px(8);
  ul {
    display: flex;
    align-items: end;

    li:last-child {
      margin-left: px(20);
    }
  }
}
.right-body {
  margin-top: px(10);
  background-color: #fff;
  padding: px(10);
  border-radius: px(8);
  .btn-box {
  }
}

.dialog-pow-body {
  display: flex;
  justify-content: center;
  align-items: center;
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      width: 80%;
      display: flex;
      margin-bottom: px(10);
      align-items: center;
      justify-content: center;
      span {
        width: 40%;
        text-align: right;
        margin-right: px(10);
        i {
          color: red;
          margin-right: px(5);
        }
      }
      p {
        width: 60%;
      }
    }
  }
}
.dialog-user-body {
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      width: 90%;
      display: flex;
      margin-bottom: px(10);
      //align-items: center;
      span {
        width: 20%;
        text-align: right;
        margin-right: px(10);
        i {
          color: red;
          margin-right: px(5);
        }
      }
      p {
        width: 80%;
      }
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
}
::v-deep .el-dialog__footer {
  background: #fff;
}
.state-box {
  border-radius: px(18);
  padding: px(5) px(10);
  color: #fff;
}
.left-content {
  margin-top: px(10);
  overflow: auto;
  //text-overflow:ellipsis;
  height: 77vh;
  ::v-deep .el-tree-node__content {
    .el-tree-node__label {
      overflow: auto;
    }
    
    .el-tree-node__label::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 4px;
    }
    .el-tree-node__label::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      box-shadow: inset 0 0 5px rgba(97, 184, 179, 0.1);
      background: #999;
    }
    .el-tree-node__label::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      box-shadow: inset 0 0 5px rgba(87, 175, 187, 0.1);
      border-radius: 10px;
      background: #ededed;
    }

  } 
}
.divider {
  margin: 10px 0;
}

.parentBox {
  position: relative;
  .treeBox {
    width: 100%;
    height: px(200);
    top: 35px;
    left: 0;
    position: absolute;
    overflow: auto;
    z-index: 99;
    background-color: #fefefe;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  }
}
.li-timePick-box {
  ::v-deep .calendarDate {
    width: px(180);
  }
}

::v-deep .dialog_global {
  .el-dialog__header {
    /**padding-top: px(10) !important;
    padding-bottom: px(10) !important;**/
  }

  margin-top: 5vh !important;
  .el-dialog__body {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
.langInput {
  width: 300px;
  ::v-deep .el-input__inner{
    width: 300px;
  }
}
</style>