import request from '@/utils/request'

const api = {
  // 系统菜单管理
  system_menu(params) {
    return request({
      url: '/api/system_menu/',
      method: 'get',
      params
    })
  },
  // 菜单管理新增 目录，菜单，按钮
  system_menu_add(data) {
    return request({
      url: '/api/system_menu_add/',
      method: 'post',
       data
    })
  },
   // 获取上级数据字典
   system_menu_data(params) {
    return request({
      url: '/api/system_menu_data/',
      method: 'get',
      params
    })
  },
   // 修改菜单信息
   system_menu_put(data) {
    return request({
      url: '/api/system_menu_put/',
      method: 'post',
      data
    })
  },
  // 删除菜单管理
  system_menu_delete(data) {
    return request({
      url: '/api/system_menu_delete/',
      method: 'post',
      data
    })
  },
  // 系统角色管理列表
  system_role(params) {
    return request({
      url: '/api/system_role/',
      method: 'get',
      params
    })
  },
   // 获取系统角色API接口字段数据字典
   system_role_page_api(params) {
    return request({
      url: '/api/system_role_page_api/',
      method: 'get',
      params
    })
  },
  // 获取角色对应的权限详细信息
  system_role_permissions(params) {
    return request({
      url: '/api/system_role_permissions/',
      method: 'get',
      params
    })
  },
  // 新增/修改角色 菜单/数据权限
  system_role_permissions_put(data) {
    return request({
      url: '/api/system_role_permissions_put/',
      method: 'post',
      data
    })
  },
  // 新增系统角色管理
  system_role_add(data) {
    return request({
      url: '/api/system_role_add/',
      method: 'post',
      data
    })
  },
  // 修改系统角色信息
  system_role_put(data) {
    return request({
      url: '/api/system_role_put/',
      method: 'post',
      data
    })
  },
   // 删除系统用户角色
  system_role_delete(data) {
    return request({
      url: '/api/system_role_delete/',
      method: 'post',
      data
    })
  },
  // 获取角色管理仪器信息对应数据字典
  system_instrument_info(data) {
    return request({
      url: '/api/system_instrument_info/',
      method: 'post',
      data
    })
  },



  // 获取系统所有用户
  system_user(params) {
    return request({
      url: '/api/system_user/',
      method: 'get',
      params
    })
  },
  // 新增系统用户
  system_user_add(data) {
    return request({
      url: '/api/system_user_add/',
      method: 'post',
      data
    })
  },
  // 修改用户信息
  system_user_put(data) {
    return request({
      url: '/api/system_user_put/',
      method: 'post',
      data
    })
  },
  // 系统用户密码重置
  system_user_reset_passwd(data) {
    return request({
      url: '/api/system_user_reset_passwd/',
      method: 'post',
      data
    })
  },
  // 获取用户详细信息
  system_user_info(params) {
    return request({
      url: '/api/system_user_info/',
      method: 'get',
      params
    })
  },
  // 删除禁用系统用户角色
  system_user_delete(data) {
    return request({
      url: '/api/system_user_delete/',
      method: 'post',
      data
    })
  },
  // 获取系统职位信息
  system_post(params) {
    return request({
      url: '/api/system_post/',
      method: 'get',
      params
    })
  },

  // 获取系统管理组织架构列表
  system_org(params) {
    return request({
      url: '/api/system_org/',
      method: 'get',
      params
    })
  },
  // 新增组织架构
  system_org_add(data) {
    return request({
      url: '/api/system_org_add/',
      method: 'post',
      data
    })
  },
  
  // 修改组织架构
  system_org_put(data) {
    return request({
      url: '/api/system_org_put/',
      method: 'post',
      data
    })
  },
  // 删除组织架构中的机构
  system_org_delete(data) {
    return request({
      url: '/api/system_org_delete/',
      method: 'post',
      data
    })
  },
  // 新增部门
  system_dept_add(data) {
    return request({
      url: '/api/system_dept_add/',
      method: 'post',
      data
    })
  },
  // 修改部门信息
  system_dept_put(data) {
    return request({
      url: '/api/system_dept_put/',
      method: 'post',
      data
    })
  },
  // 删除系统部门
  system_dept_delete(data) {
    return request({
      url: '/api/system_dept_delete/',
      method: 'post',
      data
    })
  },
}

export default api