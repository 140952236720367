import { Message } from 'element-ui'

export function validateMobile(mobileNumber) {
    let regs = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
    if(regs.test(mobileNumber)){
        return true
    }else{
        Message.warning("请输入正确的手机号")
		return false
	}
}